import React, { useEffect, useState } from 'react';
import './EndResponsibilitiesCheckbox.scss';

const EndResponsibilitiesCheckbox: React.FC = () => {
  const [endResponsibilities, setEndResponsibilities] = useState(false);

  useEffect(() => {
    const storedValue = sessionStorage.getItem('endResponsibilities');
    if (storedValue !== null) {
      setEndResponsibilities(storedValue === 'true');
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setEndResponsibilities(checked);
    sessionStorage.setItem('endResponsibilities', checked.toString());
    window.location.href = '/home/leerplan';
    window.location.reload();
  };

  return (
    <div data-testid="end-responsibilities-checkbox" className="end-responsibilities-checkbox">
      <label className="labeltext" htmlFor="end-responsibilities-checkbox">
        End Responsibilities
      </label>
      <input
        id="end-responsibilities-checkbox"
        type="checkbox"
        checked={endResponsibilities}
        onChange={handleChange}
      />
    </div>
  );
};

export default EndResponsibilitiesCheckbox;
