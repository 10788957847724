const apisAndUrls = {
  cachedApi: {
    baseUrl: "https://cached-api.katholiekonderwijs.vlaanderen",
  },
  api: "https://api.katholiekonderwijs.vlaanderen",
  customcurapi: {
    baseUrl: "https://dcustomcurricula-api.katholiekonderwijs.vlaanderen",
    //baseUrl: 'https://api.katholiekonderwijs.vlaanderen',
    groupBy: 70,
  },
  activityplansapi: {
    baseUrl: "https://dactivityplans-api.katholiekonderwijs.vlaanderen",
    //baseUrl: 'https://api.katholiekonderwijs.vlaanderen',
  },
  securityapi: {
    baseUrl: "https://beveiliging-nodejs.katholiekonderwijs.vlaanderen",
    //baseUrl: 'https://api.katholiekonderwijs.vlaanderen',
  },
  myVsko: "http://mijn.katholiekonderwijs.vlaanderen",
};

export default apisAndUrls;
