const demo = {
  enableDemoMode: true,
  demo: {
    logoDirectsTo: "home.leerplan",
    showReadOrder: false,
    demoVersion: "2024-2025",
  },
};

export default demo;
