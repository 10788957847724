import React, { useEffect, useState } from 'react';
import './SchoolEndDateField.scss';

const SchoolEndDateField: React.FC = () => {
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const storedValue = sessionStorage.getItem('schoolEndDate');
    if (storedValue !== null) {
      setEndDate(storedValue);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEndDate(value);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    sessionStorage.setItem('schoolEndDate', value);
    window.location.href = '/home/leerplan';
    window.location.reload();
  };

  return (
    <div data-testid="school-end-date-field" className="end-date-field">
      <input
        type="text"
        placeholder="School end date"
        value={endDate}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default SchoolEndDateField;
